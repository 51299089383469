import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
import './Login.css';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: '',
            isAuthenticated: false, // Manage redirection
            token: ''
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        /*const saltRounds = 10;*/

        //try {
        //    // Hash the password
        //    const hashpassword = await new Promise((resolve, reject) => {
        //        bcrypt.hash(password, saltRounds, (err, hash) => {
        //            if (err) {
        //                reject(err);
        //            } else {
        //                resolve(hash);
        //            }
        //        });
        //    });
        try {
            const response = await fetch('/account/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            });

            // Log response status and text for debugging
            /* console.log('Response Status:', response.status);*/
            const responseText = await response.text();
            /*console.log('Response Text:', responseText);*/

            if (!response.ok) {
                this.setState({ error: responseText || 'Invalid credentials' });
                return;
            }

            // Attempt to parse JSON response
            try {
                const result = JSON.parse(responseText);
                /*console.log('Login Result:', result); // Log to check*/
                const { token } = result;

                // Save authentication status and token
                if (token) {
                    localStorage.setItem('auth', true);
                    localStorage.setItem('token', token);
                    this.setState({ isAuthenticated: true, token }); // Update state to trigger redirect
                } else {
                    this.setState({ error: 'No token received' });
                }
            } catch (jsonError) {
                console.error('Error parsing JSON:', jsonError);
                this.setState({ error: 'Failed to parse server response' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const { email, password, error, isAuthenticated } = this.state;

        if (isAuthenticated) {
            return <Navigate to="/inventorysystem" />; // Redirect to InventorySystem component
        }

        return (
            <div className="login-container">
                <h2 className="login-title">Login</h2>
                {error && <p className="login-error">{error}</p>}
                <form className="login-form" onSubmit={this.handleSubmit}>
                    <div className="Login-form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                            className="form-control"
                        />
                    </div>
                    <div className="Login-form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            required
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        );
    }
}