import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import './CreateAccount.css';
import PasswordStrengthMeter from './PasswordStrengthMeter'; // Import the PasswordStrengthMeter

export class CreateAccount extends Component {
    static displayName = CreateAccount.name;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            error: '',
            isAuthenticated: false,
            token: ''
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password, confirmPassword } = this.state;

        if (!this.validatePassword(password)) {
            this.setState({ error: 'Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.' });
            return;
        }

        if (password !== confirmPassword) {
            this.setState({ error: 'Passwords do not match' });
            return;
        }

        try {
            const createResponse = await fetch('/account/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password, confirmPassword })
            });

            if (!createResponse.ok) {
                let errorMessage;
                switch (createResponse.status) {
                    case 400:
                        errorMessage = 'Bad Request: Please check your input.';
                        break;
                    case 401:
                        errorMessage = 'Unauthorized: You do not have permission.';
                        break;
                    case 409:
                        errorMessage = 'Conflict: An account with this email already exists.';
                        break;
                    case 500:
                        errorMessage = 'Server Error: There was an issue creating your account. Please try again later.';
                        break;
                    default:
                        errorMessage = 'Unknown Error: Please try again later.';
                }
                const errorText = await createResponse.text();
                console.error('Account Creation Error:', createResponse.status, errorText);
                this.setState({ error: errorMessage });
                return;
            }

            await this.handleLogin(email, password);
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred: ' + error.message });
        }
    };

    handleLogin = async (email, password) => {
        try {
            const response = await fetch('/account/createlogin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });

            const responseText = await response.text();

            if (!response.ok) {
                this.setState({ error: responseText || 'Invalid credentials' });
                return;
            }

            try {
                const result = JSON.parse(responseText);
                const { token } = result;

                if (token) {
                    localStorage.setItem('auth', true);
                    localStorage.setItem('token', token);
                    this.setState({ isAuthenticated: true, token });
                } else {
                    this.setState({ error: 'No token received' });
                }
            } catch (jsonError) {
                console.error('Error parsing JSON:', jsonError);
                this.setState({ error: 'Failed to parse server response' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    validatePassword = (password) => {
        //check for lowercase
        const lowercaseTest = /^.*[a-z].*$/;
        //check for uppercase
        const uppercaseTest = /^.*[A-Z].*$/;
        //check for numbers
        const digitTest = /^.*[\d].*$/;
        //check for special characters exluding newlines
        const specialCharacterTest = /^.*[^a-zA-Z\d\n].*$/;
        //check if its 8 or more characters
        const characterMinimumTest = /^.{8,}$/;
        if (lowercaseTest.test(password) === false ||
            uppercaseTest.test(password) === false ||
            digitTest.test(password) === false ||
            specialCharacterTest.test(password) === false ||
            characterMinimumTest.test(password) === false
        ) {
            return false;
        }

        return true;
    };

    render() {
        const { email, password, confirmPassword, error, isAuthenticated } = this.state;

        if (isAuthenticated) {
            return <Navigate to="/subscription" />;
        }

        return (
            <div className="create-account-container">
                <h2 className="create-account-title">Create Account</h2>
                {error && <p className="create-account-error">{error}</p>}
                <form className="create-account-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                            className="create-account-form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            required
                            className="create-account-form-control"
                        />
                        <PasswordStrengthMeter password={password} /> {/* Add the strength meter */}
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={this.handleChange}
                            required
                            className="create-account-form-control"
                        />
                    </div>
                    <button type="submit" className="create-account-button">Create Account</button>
                </form>
            </div>
        );
    }
}