import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import './AnalysisPage.css';


export class AnalysisPage extends Component {
    static displayName = AnalysisPage.name;

    constructor(props) {
        super(props);
        this.state = {
            navigateinvreturn: false,
            wasteItemName: '',
            wasteQuantity: 0,
            wasteReason: '',
            wasteEmployee: '',
            wasteManager: '',
            wasteDate: '',
            wasteRecords: [],
            startDate: 0,
            endDate: 0,
            items: [],
            itemName: '',
            units: 0,
            amount: 0,
            costPerUnit: 0,
            totalCost: 0,
            userEmail: '',
            BusinessPlan: '',
            userID: '',
            isAuthenticated: true,
            error: null,
            wasteChartData: [],
            inventoryChartData: [],
            showSTDChart: false,
            debounceTimeout: null 
        };
    }

    async componentDidMount() {
        const token = localStorage.getItem('token');

        if (!token) {
            this.setState({ isAuthenticated: false });
            return;
        }

        await this.fetchUserDetails(token);
        await this.fetchInventoryItems(token);
        await this.fetchRecords(token);
    }

    fetchUserDetails = async (token) => {
        try {
            const response = await fetch('/user/userdetails', {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user details');
            }

            const user = await response.json();
            this.setState({
                userEmail: user.email,
                BusinessPlan: user.businessPlan,
                userID: user.UserId,
                isAuthenticated: true,
            });
            
        } catch (error) {
            console.error(error);
            this.setState({ isAuthenticated: false });
        }
    };

    fetchInventoryItems = async (token) => {
        try {
            const response = await fetch('/inventory/items', {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }

            const items = await response.json();
            this.setState({ items }); // Set items directly
        } catch (error) {
            console.error(error);
            this.setState({ error: error.message });
        }
    };

    fetchRecords = async (token) => {
        try {
            const response = await fetch('/foodwaste/records', {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch records');
            }

            const records = await response.json();
            this.updateDateRange(records);
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    };
    handleDateChange = async (e) => {
        // Update state with the new value
        this.setState({ [e.target.name]: e.target.value }, async () => {
            const { startDate, endDate } = this.state;

            // Check if startDate and endDate are provided
            if (startDate && endDate) {
                try {
                    const token = localStorage.getItem('token');
                    const query = new URLSearchParams({
                        startDate: startDate,
                        endDate: endDate
                    }).toString();

                    const response = await fetch(`/foodwaste/records?${query}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const records = await response.json();
                        // Handle items here, e.g., set them in state
                        const sortedrecords = records.sort((a, b) => new Date(a.date) - new Date(b.date));

                        // Handle sorted items here, e.g., set them in state
                        this.setState({ wasteRecords: sortedrecords }); // Example
                    } else {
                        const errorText = await response.text();
                        this.setState({ error: errorText || 'Failed to fetch items' });
                    }
                } catch (error) {
                    console.error('Error:', error);
                    this.setState({ error: 'An unexpected error occurred' });
                }
            } else {
                console.error('Start Date and End Date must be provided.');
                this.setState({ error: 'Start Date and End Date must be provided.' });
            }
        });
    };
    updateDateRange = (records) => {
        const currentDate = new Date();
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        this.setState({
            wasteRecords: records,
            startDate: firstDay.toISOString().split('T')[0],
            endDate: lastDay.toISOString().split('T')[0]
        }, () => {
            this.handleDateChange({ target: { name: 'startDate', value: this.state.startDate } });
            this.handleDateChange({ target: { name: 'endDate', value: this.state.endDate } });
        });
    };
    toggleChartType = () => {
        // Clear the previous timeout if it exists
        if (this.state.debounceTimeout) {
            clearTimeout(this.state.debounceTimeout);
        }

        // Set a new timeout to prevent spamming
        const debounceTimeout = setTimeout(() => {
            this.setState(prevState => ({ showSTDChart: !prevState.showSTDChart }));
        }, 300); // Adjust delay as needed (300 ms here)

        // Save the timeout ID to state
        this.setState({ debounceTimeout });
    }

    handleinvreturn = () => {
        this.setState({ navigateinvreturn: true });
    };
    
    buildCharts = () => {
        const wasteData = this.state.wasteRecords.map(record => ({
            name: record.wasteItemName,
            value: record.wasteQuantity,
        }));

        const inventoryData = this.state.items.map(item => ({
            name: item.itemName,
            value: item.units,
        }));

        this.setState({ wasteChartData: wasteData, inventoryChartData: inventoryData });
    };

    renderBasicBarChart(data) {
        if (data.length === 0) {
            return <p>No data available</p>; // Handle empty data
        }

        //const maxValue = Math.max(...data.map(item => item.value));
        return (
            <div className="analysis-chart-container">
                {data.map(item => (
                    <div key={item.name} className="analysis-chart-bar">
                        <span className="analysis-chart-bar-label">{item.name}</span>
                        <div
                            className="analysis-chart-bar-fill"
                            style={{ width: `${(item.value / 100) * 100}%` }}
                        />
                        <span className="analysis-chart-bar-value">{item.value}</span>
                    </div>
                ))}
            </div>
        );
    }
    calculateStandardDeviation(data) {
        const values = data.map(item => item.value);
        const mean = values.reduce((sum, value) => sum + value, 0) / values.length;

        const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
        return Math.sqrt(variance);
    }

    renderSTDBarChart(data) {
        if (data.length === 0) {
            return <p>No data available</p>; // Handle empty data
        }

        // Calculate standard deviation
        const stdDev = this.calculateStandardDeviation(data);

        return (
            <div className="analysis-chart-container">
                <div className="std-dev-label">Standard Deviation: {stdDev.toFixed(2)}</div> {/* Display STD */}
                {data.map(item => {
                    const isAboveMean = item.value > stdDev; // Check if above the standard deviation
                    return (
                        <div key={item.name} className="analysis-chart-bar">
                            <span className="analysis-chart-bar-label">{item.name}</span>
                            <div
                                className="analysis-chart-bar-fill"
                                style={{
                                    width: `${(item.value / 100) * 100}%`,
                                    backgroundColor: isAboveMean ? 'lightcoral' : 'lightblue' // Color based on condition
                                }}
                            />
                            <span className="analysis-chart-bar-value">{item.value}</span>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { navigateinvreturn, wasteChartData, inventoryChartData, showSTDChart } = this.state;

        if (navigateinvreturn) {
            return <Navigate to="/inventorysystem" />;
        }

        return (
            <div>
                <h1 className="analysis-title">Analysis</h1>
                <button className="analysis-button" onClick={this.handleinvreturn}>Return</button>
                <div className="analysis-form-group">
                    <label htmlFor="startDate" className="analysis-label">Start Date:</label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="analysis-input-date"
                        value={this.state.startDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <div className="analysis-form-group">
                    <label htmlFor="endDate" className="analysis-label">End Date:</label>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="analysis-input-date"
                        value={this.state.endDate}
                        onChange={this.handleDateChange}
                    />
                </div>
                <button className="analysis-button" onClick={this.buildCharts}>Build Charts</button>

                <button className="analysis-button" onClick={this.toggleChartType}>
                    {showSTDChart ? "Show Basic Chart" : "Show STD Chart"}
                </button>

                <h2 className="analysis-subtitle">Waste Details Chart</h2>
                {showSTDChart
                    ? this.renderSTDBarChart(wasteChartData)
                    : this.renderBasicBarChart(wasteChartData)}

                <h2 className="analysis-subtitle">Inventory Items Chart</h2>
                {showSTDChart
                    ? this.renderSTDBarChart(inventoryChartData)
                    : this.renderBasicBarChart(inventoryChartData)}
            </div>
        );
    }
}