/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import './InventorySystem.css';

export class InventorySystem extends Component {
    static displayName = InventorySystem.name;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            items2: [],
            items3: [],
            items4: [],
            items5: [],
            productname: '',
            distributor: '',
            units: 0,
            cases: 0,
            costPerCase: 0,
            costPerUnit: 0,
            totalCost: 0,
            userEmail: '',
            userholderEmail: '',
            BusinessPlan: '',
            packSize: '',
            userID: 0,
            error: '',
            isAuthenticated: true,
            editingItem: null,
            navigateToFoodWaste: false,
            navigateToAnalysis: false,
            ActivatedDatabaseTwo: false,
            ActivatedDatabaseThree: false,
            ActivatedDatabaseFour: false,
            ActivatedDatabaseFive: false,
            startDate: '', // New state variable for start date
            endDate: '',    // New state variable for end date
            startDate2: '',
            endDate2: '',
            startDate3: '',
            endDate3: '',
            startDate4: '',
            endDate4: '',
            startDate5: '',
            endDate5: '',
            Wtable: '',
            currentSet: 1,
            showSecondDates: false,
            showThirdDates: false,
            showFourDates: false,
            showFiveDates: false,
            settingsVisible: false,
            addtableVisible: false,
            isButtonClickable: false,
            ExportsVisible: false,
            warningsetting: 0,  // New state for setting 1
            critialsetting: 0,  // New state for setting 2
            Invnameone: '',
            Invnametwo: '',
            Invnamethree: '',
            Invnamefour: '',
            Invnamefive: ''
        };
    }

    async componentDidMount() {
        const token = localStorage.getItem('token');
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js';

        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            console.log('Stripe script loaded successfully.');
        };

        script.onerror = () => {
            console.error('Failed to load Stripe script.');
        };

        if (!token) {
            this.setState({ isAuthenticated: false });
            return;
        }

        try {
            const userResponse = await fetch('/user/userdetails', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (userResponse.ok) {
                const user = await userResponse.json();
                this.setState({
                    userEmail: user.email, ActivatedDatabaseTwo: user.activatedDatabaseTwo, ActivatedDatabaseThree: user.activatedDatabasethree,
                    ActivatedDatabaseFour: user.activatedDatabasefour, ActivatedDatabaseFive: user.activatedDatabasefive,
                    BusinessPlan: user.businessPlan, userID: user.userid, isAuthenticated: true
                });
                this.fetchSettingsByUserId(user.userid);
            } else {
                const errorText = await userResponse.text();
                console.error('Failed to fetch user details', userResponse.status, errorText);
                this.setState({ isAuthenticated: false });
                return;
            }

            const inventoryResponse = await fetch('/inventory/items', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (inventoryResponse.ok) {
                const items = await inventoryResponse.json();
                const currentDate = new Date();

                // Get the first day of the current month
                const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

                // Get the last day of the current month
                const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

                const sortedItems = items.map(item => ({
                    ...item,
                    itemState: this.calculateItemState(item.units, item.packSize),
                })).sort((a, b) => {
                    const stateOrder = {
                        'critical': 1,
                        'warning': 2,
                        'normal': 3,
                    };
                    return stateOrder[a.itemState] - stateOrder[b.itemState];
                });
                this.setState({
                    items: sortedItems,
                    startDate: firstDay.toISOString().split('T')[0],
                    endDate: lastDay.toISOString().split('T')[0],
                    startDate2: firstDay.toISOString().split('T')[0],
                    endDate2: lastDay.toISOString().split('T')[0],
                    startDate3: firstDay.toISOString().split('T')[0],
                    endDate3: lastDay.toISOString().split('T')[0],
                    startDate4: firstDay.toISOString().split('T')[0],
                    endDate4: lastDay.toISOString().split('T')[0],
                    startDate5: firstDay.toISOString().split('T')[0],
                    endDate5: lastDay.toISOString().split('T')[0]
                }, () => {
                    // Call handleDateChange after state has been updated
                    this.handleDateFiveInvChange({ target: { name: 'startDate5', value: this.state.startDate5 } });
                    this.handleDateFiveInvChange({ target: { name: 'endDate5', value: this.state.endDate5 } });
                    this.handleDateFourInvChange({ target: { name: 'startDate4', value: this.state.startDate4 } });
                    this.handleDateFourInvChange({ target: { name: 'endDate4', value: this.state.endDate4 } });
                    this.handleDateThirdInvChange({ target: { name: 'startDate3', value: this.state.startDate3 } });
                    this.handleDateThirdInvChange({ target: { name: 'endDate3', value: this.state.endDate3 } });
                    this.handleDateSecondInvChange({ target: { name: 'startDate2', value: this.state.startDate2 } });
                    this.handleDateSecondInvChange({ target: { name: 'endDate2', value: this.state.endDate2 } });
                    this.handleDateChange({ target: { name: 'startDate', value: this.state.startDate } });
                    this.handleDateChange({ target: { name: 'endDate', value: this.state.endDate } });
                    
                });
            } else {
                const errorText = await inventoryResponse.text();
                this.setState({ error: errorText || 'Failed to fetch items' });
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ error: 'An unexpected error occurred', isAuthenticated: false });
        }
    }
    fetchSettingsByUserId = async (userid) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`/inventory/setting?userId=${userid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const settings = await response.json();
                this.setState({
                    warningsetting: settings.warning,
                    critialsetting: settings.critical,
                    Invnameone: settings.invnameone,
                    Invnametwo: settings.invnametwo,
                    Invnamethree: settings.invnamethree,
                    Invnamefour: settings.invnamefour,
                    Invnamefive: settings.invnamefive

                },
                    () => this.fetchItems(token,'first')
                );
            } else {
                const errorText = await response.text();
                console.error('Failed to fetch settings:', errorText);
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };
    fetchItems = async (token, type) => {
        let startDateKey, endDateKey, endpoint;

        switch (type) {
            case 'first':
                startDateKey = 'startDate';
                endDateKey = 'endDate';
                endpoint = '/inventory/items';
                break;
            case 'second':
                startDateKey = 'startDate2';
                endDateKey = 'endDate2';
                endpoint = '/inventory/items2';
                break;
            case 'third':
                startDateKey = 'startDate3';
                endDateKey = 'endDate3';
                endpoint = '/inventory/items3';
                break;
            case 'fourth':
                startDateKey = 'startDate4';
                endDateKey = 'endDate4';
                endpoint = '/inventory/items4';
                break;
            case 'fifth':
                startDateKey = 'startDate5';
                endDateKey = 'endDate5';
                endpoint = '/inventory/items5';
                break;
            default:
                console.error(`Unknown type: ${type}`);
                return;
        }

        const startDateValue = this.state[startDateKey];
        const endDateValue = this.state[endDateKey];

        if (startDateValue && endDateValue) {
            try {
                const query = new URLSearchParams({
                    startDate: startDateValue,
                    endDate: endDateValue,
                }).toString();

                const response = await fetch(`${endpoint}?${query}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const items = await response.json();
                    const sortedItems = this.sortItems(items);
                    this.setState({
                        items: sortedItems

                    })
                } else {
                    const errorText = await response.text();
                    this.setState({ error: errorText || `Failed to fetch items for ${type}` });
                }
            } catch (error) {
                console.error(`Error fetching items for ${type}:`, error);
                this.setState({ error: 'An unexpected error occurred' });
            }
        } else {
            console.error(`Start Date and End Date must be provided for ${type}.`);
        }
    }
    fetchItemsnoDates = async (token, type) => {
        let endpoint;

        switch (type) {
            case 'first':
                endpoint = '/inventory/items';
                break;
            case 'second':
                endpoint = '/inventory/items2';
                break;
            case 'third':
                endpoint = '/inventory/items3';
                break;
            case 'fourth':
                endpoint = '/inventory/items4';
                break;
            case 'fifth':
                endpoint = '/inventory/items5';
                break;
            default:
                console.error(`Unknown type: ${type}`);
                return;
        }

        try {
            const response = await fetch(endpoint, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const items = await response.json();
                const sortedItems = this.sortItems(items);
                this.setState({
                    items: sortedItems
                
            })
            } else {
                const errorText = await response.text();
                this.setState({ error: errorText || `Failed to fetch items for ${type}` });
            }
        } catch (error) {
            console.error(`Error fetching items for ${type}:`, error);
            this.setState({ error: 'An unexpected error occurred' });
        }
    }
    sortItems = (items) => {
        const stateOrder = {
            'critical': 1,
            'warning': 2,
            'normal': 3,
        };
        return items.map(item => ({
            ...item,
            itemState: this.calculateItemState(item.units, item.packSize),
        })).sort((a, b) => stateOrder[a.itemState] - stateOrder[b.itemState]);
    }
    
    toggleSettings = () => {
        this.setState(prevState => ({ settingsVisible: !prevState.settingsVisible }));
    }; ExportsVisible
    exporttoggleSettings = () => {
        this.setState(prevState => ({ ExportsVisible: !prevState.ExportsVisible }));
    };
    handleSettingChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleAddTable = async () => {
        const {
            ActivatedDatabaseTwo,
            ActivatedDatabaseThree,
            ActivatedDatabaseFour,
            ActivatedDatabaseFive,
        } = this.state;

        // Determine which database is not activated and should be set to true
        let updatedState = {};

        if (!ActivatedDatabaseTwo) {
            updatedState = { ActivatedDatabaseTwo: true };
        } else if (!ActivatedDatabaseThree) {
            updatedState = { ActivatedDatabaseThree: true };
        } else if (!ActivatedDatabaseFour) {
            updatedState = { ActivatedDatabaseFour: true };
        } else if (!ActivatedDatabaseFive) {
            updatedState = { ActivatedDatabaseFive: true };
        } else {
            // All databases are already activated, no update needed
            alert('All databases are already activated!');
            return;
        }

        // Merge updated state into the existing state
        this.setState(updatedState);

        // Create the object to send to the API with the updated activation status
        const DatabaseActHolder = {
            ActivatedDatabaseTwo: this.state.ActivatedDatabaseTwo || updatedState.ActivatedDatabaseTwo,
            ActivatedDatabaseThree: this.state.ActivatedDatabaseThree || updatedState.ActivatedDatabaseThree,
            ActivatedDatabaseFour: this.state.ActivatedDatabaseFour || updatedState.ActivatedDatabaseFour,
            ActivatedDatabaseFive: this.state.ActivatedDatabaseFive || updatedState.ActivatedDatabaseFive,
        };

        try {
            const token = localStorage.getItem('token');

            // Make the API request to update the activated database status
            const response = await fetch('/user/activated', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(DatabaseActHolder), // Send the updated activation status
            });

            if (response.ok) {
                // Reload the page only after a successful response
                location.reload();
            } else {
                console.error('Failed to update database activation status');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    handleSetting = async () => {
        const { warningsetting, critialsetting, Invnameone, Invnametwo, Invnamethree, Invnamefour, Invnamefive, userEmail } = this.state;
        const settings = {
            Email: userEmail,
            Warning: warningsetting,
            Critical: critialsetting,
            CreatedDate: new Date().toISOString(), // Current date in ISO format
            SettingName: 'Default',
            Invnameone: Invnameone,
            Invnametwo: Invnametwo,
            Invnamethree: Invnamethree,
            Invnamefour: Invnamefour,
            Invnamefive: Invnamefive
        };

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/inventory/settings', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(settings), // Send settings directly
            }
            );
            if (response.ok) {
                // Reload the page only after a successful response
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || 'Failed to update settings');
            }

            /* console.log('Settings updated successfully:', await response.json());*/
        } catch (error) {
            console.error('Error updating settings:', error);
            this.setState({ error: 'Failed to update settings' });
        }
    };

    calculateItemState = (units,packSize) => {
        const { critialsetting, warningsetting } = this.state;

        // Extract the first number from packSize
        
        const firstPart = packSize.split('/')[0];
        let firstNumber;

        if (firstPart && !isNaN(parseInt(firstPart, 10))) {
            firstNumber = firstNumber = parseInt(packSize.split('/')[0], 10);
        } else {
            firstNumber = 0; // Default to 0 if not valid
        }
        // Use firstNumber as needed; you might want to use it with units
        // Example: If you want to add it to units, you could do:
        const totalUnits = units + firstNumber;

        if (totalUnits <= critialsetting) return 'critical';
        if (totalUnits <= warningsetting) return 'warning';
        return 'normal';
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, this.calculateTotalCost);
    };

    calculateTotalCost = () => {
        const { units, costPerCase, cases } = this.state;
        const costPerUnit = (costPerCase / units).toFixed(2);
        const totalCost = ((units * costPerUnit) + (cases * costPerCase)).toFixed(2);;

        this.setState({ totalCost, costPerUnit });
    };
    handleDateChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            const token = localStorage.getItem('token');
            if (token) {
                this.fetchItems(token, 'first'); // Fetch items for the first inventory set
            }
        });
    }
    handleDateFiveInvChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            const token = localStorage.getItem('token');
            if (token) {
                this.fetchItems(token, 'fifth'); // Fetch items for the second inventory set
            }
        });
    }
    handleDateFourInvChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            const token = localStorage.getItem('token');
            if (token) {
                this.fetchItems(token, 'fourth'); // Fetch items for the second inventory set
            }
        });
    }
    handleDateThirdInvChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            const token = localStorage.getItem('token');
            if (token) {
                this.fetchItems(token, 'third'); // Fetch items for the second inventory set
            }
        });
    }
    handleDateSecondInvChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            const token = localStorage.getItem('token');
            if (token) {
                this.fetchItems(token, 'second'); // Fetch items for the second inventory set
            }
        });
    }
    fetchFirstInventory = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            this.setState({ currentSet : 1})
            await this.fetchItemsnoDates(token, 'first');
        }
    };

    fetchSecondInventory = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            this.setState({ currentSet: 2 })
            await this.fetchItemsnoDates(token, 'second');
        }
    };
    fetchThirdInventory = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            this.setState({ currentSet: 3 })
            await this.fetchItemsnoDates(token, 'third');
        }
    };

    fetchFourInventory = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            this.setState({ currentSet: 4 })
            await this.fetchItemsnoDates(token, 'fourth');
        }
    };
    fetchfiveInventory = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            this.setState({ currentSet: 5 })
            await this.fetchItemsnoDates(token, 'fifth');
        }
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.submitForm(e, '/inventory/add', `/inventory/update/${this.state.editingItem?.id}`);
    };

    handleSubmit2 = (e) => {
        e.preventDefault();
        this.submitForm(e, '/inventory/add2', `/inventory/update2/${this.state.editingItem?.id}`);
    };
    handleSubmit3 = (e) => {
        e.preventDefault();
        this.submitForm(e, '/inventory/add3', `/inventory/update3/${this.state.editingItem?.id}`);
    };
    handleSubmit4 = (e) => {
        e.preventDefault();
        this.submitForm(e, '/inventory/add4', `/inventory/update4/${this.state.editingItem?.id}`);
    };
    handleSubmit5 = (e) => {
        e.preventDefault();
        this.submitForm(e, '/inventory/add5', `/inventory/update5/${this.state.editingItem?.id}`);
    };

    submitForm = async (e, addUrl, updateUrl) => {
        const { productname, units, packSize, cases, costPerUnit, totalCost, editingItem, distributor, costPerCase } = this.state;
        const token = localStorage.getItem('token');

        if (productname && distributor && units >= 0 && cases >= 0 && costPerUnit >= 0 && packSize) {
            const itemData = { productname, units, cases, costPerUnit, totalCost, packSize, distributor, costPerCase };

            try {
                const response = editingItem
                    ? await fetch(updateUrl, {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(itemData),
                    })
                    : await fetch(addUrl, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(itemData),
                    });

                if (response.ok) {
                    const updatedItem = await response.json();
                    this.updateItemsState(updatedItem, editingItem);
                } else {
                    console.error('Failed to save inventory item');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert('Please fill out all fields with valid data.');
        }
    };

    updateItemsState = (updatedItem, editingItem) => {
        this.setState((prevState) => {
            const items = editingItem
                ? prevState.items.map(item => item.id === editingItem.id
                    ? { ...updatedItem, itemState: this.calculateItemState(updatedItem.units, updatedItem.packSize) }  // Update the existing item
                    : item)
                : [...prevState.items, { ...updatedItem, itemState: this.calculateItemState(updatedItem.units, updatedItem.packSize) }]; // Add a new item

            return {
                items,
                productname: '',
                distributor: '',
                packSize: '',
                units: 0,
                cases: 0,
                costPerCase: 0,
                costPerUnit: 0,
                totalCost: 0,
                editingItem: null,
            };
        });
    };
    
    handleEdit = (item) => {
        this.setState({
            productname: item.productName,
            distributor: item.distributor,
            packSize: item.packSize,
            units: item.units,
            cases: item.cases,
            costPerCase: item.costPerCase,
            costPerUnit: item.costPerUnit,
            totalCost: item.totalCost,
            editingItem: item,
        });
    };
    handlerefresh = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }
    handleLogout = async () => {
        try {
            // Make the API call to check all accounts
            await fetch('/user/checkAllAccountsBusinessPlan', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}` // Optional
                }
            });

            // Proceed with logout
            localStorage.removeItem('token');
            localStorage.removeItem('auth');
            this.setState({ isAuthenticated: false, userEmail: '', userID: '' });
            console.log("Logout successful.");
        } catch (error) {
            console.error("Error during logout:", error);
            // Optionally handle error (e.g., show a notification)
        }
    };
    handleEmailChange = (e) => {
        this.setState({ userholderEmail: e.target.value });
    };
    toggleAddTable = () => {
        this.setState(prevState => ({
            addTableVisible: !prevState.addTableVisible,
        }));
    };
    //handleAddTable = () => {
    //    const { userEmail } = this.state;
    //    if (userEmail) {
    //        // Add your logic to buy the table here
    //        alert('Table bought successfully!');
    //    } else {
    //        alert('Please enter your password!');
    //    }
    //};
    componentDidUpdate(prevProps, prevState) {
        // Check if password state has changed and now has a value
        if (this.state.userholderEmail !== prevState.userholderEmail && this.state.userholderEmail) {
            this.handleStripeRedirect();
        }
    }
    handleFoodwaste = () => {
        this.setState({ navigateToFoodWaste: true });
    };
    handleAnalysis = () => {
        this.setState({ navigateToAnalysis: true });
    };
    handleStripeRedirect = () => {
        // Disable the button and then enable it after 2 minutes
        this.setState({ isButtonClickable: false });
        setTimeout(() => {
            this.setState({ isButtonClickable: true });
        }, 120000); // 2 120000 minutes (120,000 milliseconds)
    };
    handleExportpdf = async () => {
        const { userEmail } = this.state;
        const token = localStorage.getItem('token');

        if (!userEmail) {
            console.error('User Email is not available');
            return;
        }

        try {
            const response = await fetch(`/inventory/export-inventorypdf?email=${userEmail}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to export inventory');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${userEmail}_inventory.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error exporting inventory:', error);
            this.setState({ error: 'Failed to export inventory' });
        }
    };
    handleExport = async () => {
        const { userEmail } = this.state;
        const token = localStorage.getItem('token');

        if (!userEmail) {
            console.error('User Email is not available');
            return;
        }

        try {
            // Modify the endpoint to use userEmail
            const response = await fetch(`/inventory/export-inventory?email=${userEmail}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to export inventory');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${userEmail}_inventory.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error exporting inventory:', error);
            this.setState({ error: 'Failed to export inventory' });
        }
    };

    render() {
        const { productname, distributor, units, costPerCase, addTableVisible, settingsVisible, ExportsVisible, warningsetting, isButtonClickable,
            BusinessPlan, critialsetting, Invnameone, Invnametwo, Invnamethree, Invnamefour, Invnamefive, packSize, cases, costPerUnit, totalCost, items, userEmail, userHolderEmail,
            navigateToFoodWaste, navigateToAnalysis, error, isAuthenticated, editingItem, ActivatedDatabaseThree, ActivatedDatabaseFour,ActivatedDatabaseFive,currentSet, ActivatedDatabaseTwo } = this.state;

        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }
        if (navigateToFoodWaste) {
            return <Navigate to="/foodwaste" />;
        }
        if (navigateToAnalysis) {
            return <Navigate to="/analysis" />;
        }
        return (
            <div className="inventory-system">
                <h1>Inventory System</h1>

                <p>Welcome, {userEmail}</p>

                <p>Please use unique items name</p>
                <div className="container">
                    <button onClick={this.handleLogout}>Logout</button>
                    {BusinessPlan === 'premium' && (
                        <button onClick={this.handleFoodwaste}>Food Waste Tracker</button>

                    )}
                    {BusinessPlan === 'premium' && (
                        <button onClick={this.handleAnalysis}>Analysis</button>
                    )}
                    {BusinessPlan === 'premium' && (
                        <button className="toggle-button" onClick={this.exporttoggleSettings}>
                            {ExportsVisible ? 'Hide Exportorable Files' : 'Show Exportorable Files'}
                        </button>
                    )}
                    {/*{BusinessPlan === 'basic' && (*/}
                    {/*    <button onClick={this.handleExport}>Export Inventory to Excel</button>*/}
                    {/*)}*/}
                    <button className="toggle-button" onClick={this.toggleSettings}>
                        {settingsVisible ? 'Hide Settings' : 'Show Settings'}
                    </button>
                    {/*<button onClick={this.handlerefresh}>Refresh</button>*/}
                    {ExportsVisible && (
                        <div className="settings-dropdown">
                            <button className="export-button" onClick={this.handleExport}>Export Execl</button>
                            <button className="export-button" onClick={this.handleExportpdf}>Export PDF</button>
                        </div>
                    )}
                    {settingsVisible && (
                        <div className="settings-dropdown">
                            <div className="form-group">
                                <label htmlFor="warningsetting">Warning Setting:</label>
                                <input
                                    type="number"
                                    id="warningsetting"
                                    name="warningsetting"
                                    value={warningsetting}
                                    onChange={this.handleSettingChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="critialsetting">Critical Setting:</label>
                                <input
                                    type="number"
                                    id="critialsetting"
                                    name="critialsetting"
                                    value={critialsetting}
                                    onChange={this.handleSettingChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Invnameone">Name First Inventory:</label>
                                <input
                                    type="text"
                                    id="Invnameone"
                                    name="Invnameone"
                                    value={Invnameone}
                                    onChange={this.handleSettingChange}
                                />
                            </div>
                            {(BusinessPlan === 'premium' || ActivatedDatabaseTwo === true) && (
                                <div className="form-group">
                                    <label htmlFor="Invnametwo">Name Second Inventory:</label>
                                    <input
                                        type="text"
                                        id="Invnametwo"
                                        name="Invnametwo"
                                        value={Invnametwo}
                                        onChange={this.handleSettingChange}
                                    />
                                </div>
                            )}
                            {(ActivatedDatabaseThree === true) && (
                            <div className="form-group">
                                <label htmlFor="Invnamethree">Name Third Inventory:</label>
                                <input
                                    type="text"
                                    id="Invnamethree"
                                    name="Invnamethree"
                                    value={Invnamethree}
                                    onChange={this.handleSettingChange}
                                />
                            </div>
                            )}
                            {(ActivatedDatabaseFour === true) && (
                                <div className="form-group">
                                    <label htmlFor="Invnamefour">Name Four Inventory:</label>
                                    <input
                                        type="text"
                                        id="Invnamefour"
                                        name="Invnamefour"
                                        value={Invnamefour}
                                        onChange={this.handleSettingChange}
                                    />
                                </div>
                            )}
                            {(ActivatedDatabaseFive === true) && (
                                <div className="form-group">
                                    <label htmlFor="Invnamefive">Name Third Inventory:</label>
                                    <input
                                        type="text"
                                        id="Invnamefive"
                                        name="Invnamefive"
                                        value={Invnamefive}
                                        onChange={this.handleSettingChange}
                                    />
                                </div>
                            )}
                            <button onClick={this.handleSetting}>Save Settings</button>
                        </div>
                    )}
                </div>
                {currentSet === 1 ? (
                    <>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="productname">Account Product:</label>
                                <input
                                    type="text"
                                    id="productname"
                                    name="productname" // Updated name to match the field purpose
                                    value={productname} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="distributor">Distributor:</label>
                                <input
                                    type="text"
                                    id="distributor"
                                    name="distributor" // Updated name to match the field purpose
                                    value={distributor} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="packSize">Pack Size:</label>
                                <input
                                    type="text"
                                    id="packSize"
                                    name="packSize"
                                    value={packSize} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="cases">Cases:</label>
                                <input
                                    type="number"
                                    id="cases"
                                    name="cases"
                                    value={cases} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="units">Units:</label>
                                <input
                                    type="number"
                                    id="units"
                                    name="units"
                                    value={units} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="costPerCase">Cost per Case:</label>
                                <input
                                    type="number"
                                    id="costPerCase" // Changed id to be unique
                                    name="costPerCase" // Changed name to be unique and match purpose
                                    value={costPerCase} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="costPerUnit">Cost per Unit:</label>
                                <input
                                    type="number"
                                    id="costPerUnit"
                                    name="costPerUnit"
                                    value={costPerUnit} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="totalCost">Total Cost:</label>
                                <input
                                    type="number"
                                    id="totalCost"
                                    name="totalCost"
                                    value={totalCost} // Ensure state variable is correct
                                    readOnly
                                />
                            </div>
                            <button type="submit">{editingItem ? 'Update Item' : 'Add Item'}</button>
                        </form>
                    </>
                ) : currentSet === 2 ? (
                    <>
                        <form onSubmit={this.handleSubmit2}>
                            <div className="form-group">
                                <label htmlFor="productname">Account Product:</label>
                                <input
                                    type="text"
                                    id="productname"
                                    name="productname" // Updated name to match the field purpose
                                    value={productname} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="distributor">Distributor:</label>
                                <input
                                    type="text"
                                    id="distributor"
                                    name="distributor" // Updated name to match the field purpose
                                    value={distributor} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="packSize">Pack Size:</label>
                                <input
                                    type="text"
                                    id="packSize"
                                    name="packSize"
                                    value={packSize} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="cases">Cases:</label>
                                <input
                                    type="number"
                                    id="cases"
                                    name="cases"
                                    value={cases} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="units">Units:</label>
                                <input
                                    type="number"
                                    id="units"
                                    name="units"
                                    value={units} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="costPerCase">Cost per Case:</label>
                                <input
                                    type="number"
                                    id="costPerCase" // Changed id to be unique
                                    name="costPerCase" // Changed name to be unique and match purpose
                                    value={costPerCase} // Ensure state variable is correct
                                    onChange={this.handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="costPerUnit">Cost per Unit:</label>
                                <input
                                    type="number"
                                    id="costPerUnit"
                                    name="costPerUnit"
                                    value={costPerUnit} // Ensure state variable is correct
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="totalCost">Total Cost:</label>
                                <input
                                    type="number"
                                    id="totalCost"
                                    name="totalCost"
                                    value={totalCost} // Ensure state variable is correct
                                    readOnly
                                />
                            </div>
                            <button type="submit">{editingItem ? 'Update Item' : 'Add Item'}</button>
                        </form>
                        </>
                    ) : currentSet === 3 ? (
                            <>
                                <form onSubmit={this.handleSubmit3}>
                                    <div className="form-group">
                                        <label htmlFor="productname">Account Product:</label>
                                        <input
                                            type="text"
                                            id="productname"
                                            name="productname" // Updated name to match the field purpose
                                            value={productname} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="distributor">Distributor:</label>
                                        <input
                                            type="text"
                                            id="distributor"
                                            name="distributor" // Updated name to match the field purpose
                                            value={distributor} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="packSize">Pack Size:</label>
                                        <input
                                            type="text"
                                            id="packSize"
                                            name="packSize"
                                            value={packSize} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cases">Cases:</label>
                                        <input
                                            type="number"
                                            id="cases"
                                            name="cases"
                                            value={cases} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="units">Units:</label>
                                        <input
                                            type="number"
                                            id="units"
                                            name="units"
                                            value={units} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="costPerCase">Cost per Case:</label>
                                        <input
                                            type="number"
                                            id="costPerCase" // Changed id to be unique
                                            name="costPerCase" // Changed name to be unique and match purpose
                                            value={costPerCase} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="costPerUnit">Cost per Unit:</label>
                                        <input
                                            type="number"
                                            id="costPerUnit"
                                            name="costPerUnit"
                                            value={costPerUnit} // Ensure state variable is correct
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="totalCost">Total Cost:</label>
                                        <input
                                            type="number"
                                            id="totalCost"
                                            name="totalCost"
                                            value={totalCost} // Ensure state variable is correct
                                            readOnly
                                        />
                                    </div>
                                    <button type="submit">{editingItem ? 'Update Item' : 'Add Item'}</button>
                                </form>
                            </>
                        ) : currentSet === 4 ? (
                            <>
                                <form onSubmit={this.handleSubmit4}>
                                    <div className="form-group">
                                        <label htmlFor="productname">Account Product:</label>
                                        <input
                                            type="text"
                                            id="productname"
                                            name="productname" // Updated name to match the field purpose
                                            value={productname} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="distributor">Distributor:</label>
                                        <input
                                            type="text"
                                            id="distributor"
                                            name="distributor" // Updated name to match the field purpose
                                            value={distributor} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="packSize">Pack Size:</label>
                                        <input
                                            type="text"
                                            id="packSize"
                                            name="packSize"
                                            value={packSize} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cases">Cases:</label>
                                        <input
                                            type="number"
                                            id="cases"
                                            name="cases"
                                            value={cases} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="units">Units:</label>
                                        <input
                                            type="number"
                                            id="units"
                                            name="units"
                                            value={units} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="costPerCase">Cost per Case:</label>
                                        <input
                                            type="number"
                                            id="costPerCase" // Changed id to be unique
                                            name="costPerCase" // Changed name to be unique and match purpose
                                            value={costPerCase} // Ensure state variable is correct
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="costPerUnit">Cost per Unit:</label>
                                        <input
                                            type="number"
                                            id="costPerUnit"
                                            name="costPerUnit"
                                            value={costPerUnit} // Ensure state variable is correct
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="totalCost">Total Cost:</label>
                                        <input
                                            type="number"
                                            id="totalCost"
                                            name="totalCost"
                                            value={totalCost} // Ensure state variable is correct
                                            readOnly
                                        />
                                    </div>
                                    <button type="submit">{editingItem ? 'Update Item' : 'Add Item'}</button>
                                </form>
                                </>
                            ) : (
                                <>
                                    <form onSubmit={this.handleSubmit5}>
                                        <div className="form-group">
                                            <label htmlFor="productname">Account Product:</label>
                                            <input
                                                type="text"
                                                id="productname"
                                                name="productname" // Updated name to match the field purpose
                                                value={productname} // Ensure state variable is correct
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="distributor">Distributor:</label>
                                            <input
                                                type="text"
                                                id="distributor"
                                                name="distributor" // Updated name to match the field purpose
                                                value={distributor} // Ensure state variable is correct
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="packSize">Pack Size:</label>
                                            <input
                                                type="text"
                                                id="packSize"
                                                name="packSize"
                                                value={packSize} // Ensure state variable is correct
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cases">Cases:</label>
                                            <input
                                                type="number"
                                                id="cases"
                                                name="cases"
                                                value={cases} // Ensure state variable is correct
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="units">Units:</label>
                                            <input
                                                type="number"
                                                id="units"
                                                name="units"
                                                value={units} // Ensure state variable is correct
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerCase">Cost per Case:</label>
                                            <input
                                                type="number"
                                                id="costPerCase" // Changed id to be unique
                                                name="costPerCase" // Changed name to be unique and match purpose
                                                value={costPerCase} // Ensure state variable is correct
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerUnit">Cost per Unit:</label>
                                            <input
                                                type="number"
                                                id="costPerUnit"
                                                name="costPerUnit"
                                                value={costPerUnit} // Ensure state variable is correct
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="totalCost">Total Cost:</label>
                                            <input
                                                type="number"
                                                id="totalCost"
                                                name="totalCost"
                                                value={totalCost} // Ensure state variable is correct
                                                readOnly
                                            />
                                        </div>
                                        <button type="submit">{editingItem ? 'Update Item' : 'Add Item'}</button>
                                    </form>
                                </>
                )}
                {currentSet === 1 ? (
                    <>
                        {/* First set of dates */}
                        <div className="form-group">
                            <label htmlFor="startDate">Start Date:</label>
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={this.state.startDate}
                                onChange={this.handleDateChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="endDate">End Date:</label>
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                value={this.state.endDate}
                                onChange={this.handleDateChange}
                            />
                        </div>
                       
                        <h2>{Invnameone}</h2>
                        {addTableVisible && (
                            <div className="settings-dropdown">
                                <label htmlFor="email">Enter your email to comfirm purchase:</label>
                                <input
                                    type="text"
                                    id="email"
                                    value={userHolderEmail}
                                    onChange={this.handleEmailChange}
                                />
                                <label>Please click subscribe button and pay through stripe active comfirm button</label>
                                <stripe-buy-button
                                    buy-button-id="buy_btn_1Q7kg0P0eiQpiKfa3Hs7gFe8"
                                    publishable-key="pk_live_51Q1ektP0eiQpiKfaenkLYXj1PAhC5BDc55xIA2IXRdKXCGso5a2YewsfflSy3xnjbKZOtTN4g7xk0x4lizJK0S4600ZLuzKRbw"
                                >
                                </stripe-buy-button>
                                <button
                                    type="submit"
                                    disabled={!isButtonClickable}
                                    className={`purchase-button ${isButtonClickable ? 'enabled' : 'disabled'}`}
                                    onClick={this.handleAddTable}
                                >
                                    Confirm Purchase
                                </button>
                            </div>
                        )}
                        <button className="toggle-button" onClick={this.toggleAddTable}>
                            {addTableVisible ? 'Hide Buy Extra Table' : 'Buy Extra Table'}
                        </button>
                    </>
                ) : currentSet === 2 ? (
                    <>
                        {/* Second set of dates */}
                        <div className="form-group">
                            <label htmlFor="startDate2">Start Date:</label>
                            <input
                                type="date"
                                id="startDate2"
                                name="startDate2"
                                value={this.state.startDate2}
                                onChange={this.handleDateSecondInvChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="endDate2">End Date:</label>
                            <input
                                type="date"
                                id="endDate2"
                                name="endDate2"
                                value={this.state.endDate2}
                                onChange={this.handleDateSecondInvChange}
                            />
                        </div>
                        
                            <h2>{Invnametwo}</h2>
                            {addTableVisible && (
                                <div className="settings-dropdown">
                                    <label htmlFor="email">Enter your email to comfirm purchase:</label>
                                    <input
                                        type="text"
                                        id="email"
                                        value={userHolderEmail}
                                        onChange={this.handleEmailChange}
                                    />
                                    <label>Please click subscribe button and pay through stripe active comfirm button</label>
                                    <stripe-buy-button
                                        buy-button-id="buy_btn_1Q7kg0P0eiQpiKfa3Hs7gFe8"
                                        publishable-key="pk_live_51Q1ektP0eiQpiKfaenkLYXj1PAhC5BDc55xIA2IXRdKXCGso5a2YewsfflSy3xnjbKZOtTN4g7xk0x4lizJK0S4600ZLuzKRbw"
                                    >
                                    </stripe-buy-button>
                                    <button
                                        type="submit"
                                        disabled={!isButtonClickable}
                                        className={`purchase-button ${isButtonClickable ? 'enabled' : 'disabled'}`}
                                        onClick={this.handleAddTable}
                                    >
                                        Confirm Purchase
                                    </button>
                                </div>
                            )}
                            <button className="toggle-button" onClick={this.toggleAddTable}>
                                {addTableVisible ? 'Hide Buy Extra Table' : 'Buy Extra Table'}
                            </button>

                        </>
                    ) : currentSet === 3 ? (
                        <>
                            {/* Second set of dates */}
                            <div className="form-group">
                                <label htmlFor="startDate3">Start Date:</label>
                                <input
                                    type="date"
                                    id="startDate3"
                                    name="startDate3"
                                    value={this.state.startDate3}
                                    onChange={this.handleDateThirdInvChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="endDate3">End Date:</label>
                                <input
                                    type="date"
                                    id="endDate3"
                                    name="endDate3"
                                    value={this.state.endDate3}
                                    onChange={this.handleDateThirdInvChange}
                                />
                            </div>

                            <h2>{Invnamethree}</h2>
                            {addTableVisible && (
                                <div className="settings-dropdown">
                                    <label htmlFor="email">Enter your email to comfirm purchase:</label>
                                    <input
                                        type="text"
                                        id="email"
                                        value={userHolderEmail}
                                        onChange={this.handleEmailChange}
                                    />
                                    <label>Please click subscribe button and pay through stripe active comfirm button</label>
                                    <stripe-buy-button
                                        buy-button-id="buy_btn_1Q7kg0P0eiQpiKfa3Hs7gFe8"
                                        publishable-key="pk_live_51Q1ektP0eiQpiKfaenkLYXj1PAhC5BDc55xIA2IXRdKXCGso5a2YewsfflSy3xnjbKZOtTN4g7xk0x4lizJK0S4600ZLuzKRbw"
                                    >
                                    </stripe-buy-button>
                                    <button
                                        type="submit"
                                        disabled={!isButtonClickable}
                                        className={`purchase-button ${isButtonClickable ? 'enabled' : 'disabled'}`}
                                            onClick={this.handleAddTable}
                                    >
                                        Confirm Purchase
                                    </button>
                                </div>
                            )}
                            <button className="toggle-button" onClick={this.toggleAddTable}>
                                {addTableVisible ? 'Hide Buy Extra Table' : 'Buy Extra Table'}
                            </button>

                            </>
                        ) : currentSet === 4 ? (
                            <>
                                {/* Second set of dates */}
                                <div className="form-group">
                                    <label htmlFor="startDate4">Start Date:</label>
                                    <input
                                        type="date"
                                        id="startDate4"
                                        name="startDate4"
                                        value={this.state.startDate4}
                                        onChange={this.handleDateFourInvChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="endDate4">End Date:</label>
                                    <input
                                        type="date"
                                        id="endDate4"
                                        name="endDate4"
                                        value={this.state.endDate4}
                                        onChange={this.handleDateFourInvChange}
                                    />
                                </div>

                                <h2>{Invnamefour}</h2>
                                {addTableVisible && (
                                    <div className="settings-dropdown">
                                        <label htmlFor="email">Enter your email to comfirm purchase:</label>
                                        <input
                                            type="text"
                                            id="email"
                                            value={userHolderEmail}
                                            onChange={this.handleEmailChange}
                                        />
                                        <label>Please click subscribe button and pay through stripe active comfirm button</label>
                                        <stripe-buy-button
                                            buy-button-id="buy_btn_1Q7kg0P0eiQpiKfa3Hs7gFe8"
                                            publishable-key="pk_live_51Q1ektP0eiQpiKfaenkLYXj1PAhC5BDc55xIA2IXRdKXCGso5a2YewsfflSy3xnjbKZOtTN4g7xk0x4lizJK0S4600ZLuzKRbw"
                                        >
                                        </stripe-buy-button>
                                        <button
                                            type="submit"
                                            disabled={!isButtonClickable}
                                            className={`purchase-button ${isButtonClickable ? 'enabled' : 'disabled'}`}
                                                onClick={this.handleAddTable}
                                        >
                                            Confirm Purchase
                                        </button>
                                    </div>
                                )}
                                <button className="toggle-button" onClick={this.toggleAddTable}>
                                    {addTableVisible ? 'Hide Buy Extra Table' : 'Buy Extra Table'}
                                </button>

                                </>
                            ) : (
                                <>
                                    {/* Second set of dates */}
                                    <div className="form-group">
                                        <label htmlFor="startDate5">Start Date:</label>
                                        <input
                                            type="date"
                                            id="startDate5"
                                            name="startDate5"
                                            value={this.state.startDate5}
                                            onChange={this.handleDateFiveInvChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="endDate5">End Date:</label>
                                        <input
                                            type="date"
                                            id="endDate5"
                                            name="endDate5"
                                            value={this.state.endDate5}
                                            onChange={this.handleDateFiveInvChange}
                                        />
                                    </div>

                                    <h2>{Invnamefive}</h2>
                                    {addTableVisible && (
                                        <div className="settings-dropdown">
                                            <label htmlFor="email">Enter your email to comfirm purchase:</label>
                                            <input
                                                type="text"
                                                id="email"
                                                value={userHolderEmail}
                                                onChange={this.handleEmailChange}
                                            />
                                            <label>Please click subscribe button and pay through stripe active comfirm button</label>
                                            <stripe-buy-button
                                                buy-button-id="buy_btn_1Q7kg0P0eiQpiKfa3Hs7gFe8"
                                                publishable-key="pk_live_51Q1ektP0eiQpiKfaenkLYXj1PAhC5BDc55xIA2IXRdKXCGso5a2YewsfflSy3xnjbKZOtTN4g7xk0x4lizJK0S4600ZLuzKRbw"
                                            >
                                            </stripe-buy-button>
                                            <button
                                                type="submit"
                                                disabled={!isButtonClickable}
                                                className={`purchase-button ${isButtonClickable ? 'enabled' : 'disabled'}`}
                                                    onClick={this.handleAddTable}
                                            >
                                                Confirm Purchase
                                            </button>
                                        </div>
                                    )}
                                    <button className="toggle-button" onClick={this.toggleAddTable}>
                                        {addTableVisible ? 'Hide Buy Extra Table' : 'Buy Extra Table'}
                                    </button>

                                </>
                )}

                
                {(BusinessPlan === 'basic' || BusinessPlan === 'premium') &&
                    (<button onClick={this.fetchFirstInventory}>{Invnameone}</button>)}{(BusinessPlan === 'premium' || ActivatedDatabaseTwo === true) && 
                    (<button onClick={this.fetchSecondInventory}>{Invnametwo}</button>)}
                {(ActivatedDatabaseThree === true) && (<button onClick={this.fetchThirdInventory}>{Invnamethree}</button>)}
                {(ActivatedDatabaseFour === true) && (<button onClick={this.fetchFourInventory}>{Invnamefour}</button>)}
                {(ActivatedDatabaseFive === true) && (<button onClick={this.fetchfiveInventory}>{Invnamefive}</button>)}

                {error && <p className="inventory-error">{error}</p>}
                {items.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Account Product</th>
                                <th>Distributor</th>
                                <th>Pack Size (Units/Weight/Measurement)</th>
                                <th>Cost per Case</th>
                                <th>Cost per Unit</th>
                                <th>Cases</th>
                                <th>Units</th>
                                <th>Total Cost</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.id} className={item.itemState}>
                                    <td>{item.productName}</td>
                                    <td>{item.distributor}</td>
                                    <td>{item.packSize}</td>
                                    <td>{item.costPerCase}</td>
                                    <td>{item.costPerUnit}</td>
                                    <td>{item.cases}</td>
                                    <td>{item.units}</td>
                                    <td>{item.totalCost}</td>

                                    <td>
                                        <button onClick={() => this.handleEdit(item)}>Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No inventory items available.</p>
                )}
            </div>
        );
    }
}